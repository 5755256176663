<template>
    <div class="hot-patch">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>热更新</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card" style="min-height:100%">
            <el-form :model="hotPatchForm" :rules="hotPatchFormRules" ref="hotPatchFormRef" label-width="100px" class="demo-ruleForm">
                <el-form-item label="资源文件: " required>
                    <div v-if="!hotPatchForm.updateUrl">
                        <div class="hot-upload" @click="resourcesUpload">
                            <img src="../../assets/img/hot_patch_upload.png" alt="">
                                <p>请上传APP资源包</p>
                        </div>
                         <input type="file" ref="file" class="file" @change="resourcesChange($event)">
                    </div>
                    <div class="hot-patch-success" v-else>
                        <img src="../../assets/img/company/video_success.png" alt="">
                        <p>已成功上传资源包</p>
                    </div>
                </el-form-item>
                <el-form-item label="版本号: " prop="version">
                    <el-input v-model="hotPatchForm.version" placeholder="请输入版本号" size="small" style="width:500px"></el-input>
                </el-form-item>
                <el-form-item label="更新标题: " prop="updateTitle">
                    <el-input v-model="hotPatchForm.updateTitle" placeholder="请输入更新标题" size="small" style="width:500px"></el-input>
                </el-form-item>
                <el-form-item label="更新类型: " prop="updateType">
                    <el-select v-model="hotPatchForm.updateType" placeholder="请选择更新类型" size="small" style="width:500px">
                        <el-option label="热更新" :value="0"></el-option>
                        <el-option label="整包更新" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="更新链接: " prop="updateUrl">
                    <el-input v-model="hotPatchForm.updateUrl" disabled placeholder="请先上传版本包获取下载链接" size="small" style="width:500px"></el-input>
                </el-form-item>
                <el-form-item label="更新级别: " prop="isUpdateApp">
                    <el-select v-model="hotPatchForm.isUpdateApp" placeholder="请选择更新重要级别" size="small" style="width:500px">
                        <el-option label="不重要" :value="0"></el-option>
                        <el-option label="重要" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="更新描述: " prop="updateDesc">
                    <el-input type="textarea" v-model="hotPatchForm.updateDesc" placeholder="请输入更新描述" size="small" style="width:500px"></el-input>
                </el-form-item>
            </el-form>
            <video-tips v-if="videoStatus" :statusFlag="statusFlag"></video-tips>
            <el-button class="mybotton hot-submit" @click="updataHotPatch">提 交</el-button>
        </el-card>
    </div>
</template>
<script>
import companyRequest from '../../api/company'
import VideoTips from '../../pages/recruitment/components/VideoTips'
import axios from 'axios'
export default {
    mounted() {
        this.queryVersion()
    },
    components: {
        VideoTips
    },
    data() {
        return {
            videoStatus: false,
            statusFlag: 1,
            hotPatchForm: {
                version: null, //版本号
                updateType: null, //更新类型
                updateUrl: null, //更新地址
                isUpdateApp: null, //是否是重要的更新   0 不重要   1重要
                updateTitle: null, //更新提示
                updateDesc: null, //更新描述
            },
            hotPatchFormRules: {
                version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
                updateType: [{ required: true, message: '请选择更新类型', trigger: 'blur' }],
                updateUrl: [{ required: true, message: '请先上传版本包获取下载链接', trigger: 'blur' }],
                isUpdateApp: [{ required: true, message: '请选择更新重要级别', trigger: 'blur' }],
                updateTitle: [{ required: true, message: '请输入更新标题', trigger: 'blur' }],
                updateDesc: [{ required: true, message: '请输入更新描述', trigger: 'blur' }],
            }
        }
    },
    methods: {
        resourcesUpload() {
            console.log(22)
            this.$refs.file.click()
            // this.$refs.file.dispatchEvent(new MouseEvent('click'))
        },
        //热更新版本号
        queryVersion() {
            companyRequest.queryVersion().then((res) => {
                if (res.code === 200) {
                    this.hotPatchForm.version =res.message
                }
            })
        },
        updataHotPatch() {
            this.$refs.hotPatchFormRef.validate((valid) => {
                if (valid) {
                    companyRequest.addHotUpdate(this.hotPatchForm).then((res) => {
                        if (res.code === 200) {
                            this.$message.success('版本发布成功')
                        }
                    }).catch((err) => {
                        this.$message.error(err)
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        resourcesChange(el) {
            console.log('el', el.target.files[0])
            let formData = new FormData()
            formData.append('file', el.target.files[0])
            // // companyRequest.postImg(formData).then((ree) => {

            // // })
            this.videoStatus = true
            this.statusFlag = 1
            axios.post('/api/qiniu/upLoad', formData, 
                {   headers: {'Content-Type': 'multipart/form-data'},
                    transformRequest: [function (data) {
                        return data
                    }],
                    onUploadProgress: progressEvent => {
                        let persent = (progressEvent.loaded / progressEvent.total * 100 | 0)		//上传进度百分比
                        console.log(persent)
                    },
                }
            ).then((res) => {
                if (res.data.code === 200) {
                    this.hotPatchForm.updateUrl = res.data.data.message
                    this.videoStatus = false
                    this.statusFlag = 2
                    console.log('updateUrl', this.hotPatchForm.updateUrl)
                }
            }).catch((err) => {
                this.videoStatus = false
                this.statusFlag = 3
            })
        }
    },
}
</script>
<style lang="scss" scoped>
    .hot-patch{
        height: 100%;
        .el-card{
            margin-top: 20px;
        }
        .hot-submit{
            color: #fff;
            width: 150px;
            margin-left: 100px;
        }
        .file{
            // visibility: hidden;
            display: none;
        }
        .hot-upload{
            display: flex;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 200px;
            height: 200px;
            border: 1px solid #eee;
            img{
                width: 140px;
            }
            p{
                // margin-top: 5px;
                color: #bbb;
                font-size: 16px;
            }
        }
        .hot-patch-success{
            border: 1px solid #5cb87a;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 200px;
            height: 200px;
            img{
                width: 140px;
            }
            p{
                // margin-top: 5px;
                color: #bbb;
                font-size: 16px;
            }
        }
    }
</style>